<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="화학자재 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-material
                  :required="true"
                  :editable="editable && isUpdate"
                  label="자재코드/명"
                  name="materialCd"
                  type="codename"
                  :plantCd="data.plantCd"
                  v-model="data.materialCd">
                </c-material>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="false"
                  label="화학자재일련번호"
                  name="mdmChemMaterialId"
                  v-model="data.mdmChemMaterialId">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  :editable="editable"
                  label="납품업체"
                  name="deliveryVendorCd"
                  v-model="data.deliveryVendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  :editable="editable"
                  label="제조업체"
                  name="mfgVendorCd"
                  v-model="data.mfgVendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="보관장소"
                  name="storagePlace"
                  v-model="data.storagePlace">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  codeGroupCd="PROPERTIES_STATE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="propertiesStateCd"
                  label="성상"
                  v-model="data.propertiesStateCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="비산성(고체)"
                  name="fugacity"
                  v-model="data.fugacity">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="끓는점(액체,기체)"
                  name="boilPoint"
                  v-model="data.boilPoint">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="녹는점"
                  name="meltingPoint"
                  v-model="data.meltingPoint">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="분자량"
                  name="col7"
                  v-model="data.col7">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  codeGroupCd="USAGE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="usageCd"
                  label="용도"
                  v-model="data.usageCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable && data.usageCd=='UC00000032'"
                  label="용도(기타)"
                  name="usageEtc"
                  v-model="data.usageEtc">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-select
                  :comboItems="mixFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="mixFlag"
                  label="물질구성"
                  v-model="data.mixFlag"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="비중"
                  name="specificGravity"
                  v-model="data.specificGravity">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="CAS No."
                  name="casNo"
                  v-model="data.casNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="고유번호"
                  name="serialNo"
                  v-model="data.serialNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="KE 번호"
                  name="keNo"
                  v-model="data.keNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable"
                  label="HS번호"
                  name="hsNo"
                  v-model="data.hsNo">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-radio
                  :editable="editable"
                  :comboItems="ynFlagItems"
                  label="인허가대상 물질 여부"
                  name="licensingFlag"
                  v-model="data.licensingFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-radio
                  :editable="editable"
                  :comboItems="ynFlagItems"
                  label="위험물 여부"
                  name="dangerFlag"
                  v-model="data.dangerFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-radio
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-radio
                  :editable="editable"
                  :comboItems="psmYnItems"
                  label="PSM대상여부"
                  name="psmFlag"
                  v-model="data.psmFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <q-checkbox
                  v-model="data.makeFlag"
                  dense
                  color="orange-7"
                  label="제조"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.impFlag"
                  dense
                  color="orange-7"
                  label="수입"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.buyFlag"
                  dense
                  color="orange-7"
                  label="구매"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.usingFlag"
                  dense
                  color="orange-7"
                  label="사용"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.expFlag"
                  dense
                  color="orange-7"
                  label="수출"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.salesFlag"
                  dense
                  color="orange-7"
                  label="판매"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="deptTable"
            title="취급부서 목록"
            tableId="deptTable"
            :columns="grid.columns"
            :data="grid.data"
            :columnSetting="false"
            selection="multiple"
            rowKey="deptCd"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="305px"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" v-if="editable && !isUpdate" icon="add" @btnClicked="addrow" />
                <c-btn label="저장" v-if="editable && !isUpdate" icon="save" @btnClicked="saveDepts" />
                <c-btn label="삭제" v-if="editable && !isUpdate" icon="remove" @btnClicked="removeRow" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="11"
                  label="비고"
                  name="remarks"
                  v-model="data.remarks">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
      }),
    },
  },
  data() {
    return {
      isUpdate: true,
      saveUrl: transactionConfig.mdm.mam.chem.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'deptCd',
            field: 'deptCd',
            label: '부서코드',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서명',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        storagePlace: '',  // 보관장소
        productCategoryCd: null,  // 제품구분
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      mixFlagItems: [
        { code: 'Y', codeName: '혼합' },
        { code: 'N', codeName: '단일' },
      ],
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      psmYnItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '해당없음' },
      ],
      ynFlagItems: [
        { code: 'Y', codeName: 'Y' },
        { code: 'N', codeName: 'N' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.chem.get.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
      this.checkUrl = selectConfig.mdm.mam.chem.check.url;
      this.deptListUrl = selectConfig.mdm.mam.chem.dept.url;
      this.deptDeleteUrl = transactionConfig.mdm.mam.chem.dept.delete.url;
      this.deptSaveUrl = transactionConfig.mdm.mam.chem.dept.insert.url;
      this.getDetail();
    },
    getDetail() {
      if (this.param.mdmChemMaterialId) {
        this.$http.url = this.$format(this.detailUrl, this.param.mdmChemMaterialId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (_result.data.mdmChemMaterialId) {
            this.isUpdate = false;
          }
          this.param.plantCd = _result.data.plantCd;
          this.param.plantName = _result.data.plantName;
          this.param.materialCd = _result.data.materialCd;
          this.param.materialName = _result.data.materialName;
          this.updateMode = true;
          this.getDeptList();
        },);
      }
    },
    getDeptList() {
      this.$http.url = this.deptListUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.popupOptions.title = '취급부서 추가';
      this.popupOptions.param = {
        plantCd: this.data.plantCd
      }
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { deptCd: item.deptCd }) === -1) {
            this.grid.data.push({
              mdmChemMaterialId: this.param.mdmChemMaterialId,
              plantCd: item.plantCd,
              plantName: item.plantName,
              deptCd: item.deptCd,
              deptName: item.deptName,
              editFlag: 'C',
            })
          }
        })
      }
    },
    saveDepts() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'C'
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deptSaveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getDeptList();
              window.getApp.$emit('ALERT', {
                title: '안내', /* 안내 */
                message: '저장되었습니다.', /* 저장되었습니다. */
                type: 'success', // success / info / warning / error
              });
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', /* 안내 */
          message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['deptTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deptDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getDeptList();
              window.getApp.$emit('ALERT', {
                title: '안내', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfo() {
      if (this.param.mdmChemMaterialId) {
        this.saveUrl = transactionConfig.mdm.mam.chem.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = true;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.mdmChemMaterialId = result.data.mdmChemMaterialId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$emit('insertEquipInfo', result.data.mdmChemMaterialId);
      }
      this.getDetail();
    },
  }
};
</script>